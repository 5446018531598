
.App {
  background: #080c1e;
  position:relative;
  overflow-x: hidden;
  overflow: hidden;
  height: 7000px;
  
}

@media only screen and (max-width: 600px) {
.App {
  background: #080c1e;
  
  overflow: hidden;
  width: auto;
  height: auto;
  
  
}
}


.expPositioning{
  left: 5%;
}

.expBox{
position:absolute;
width: 870px;
height: 192px;
left: 400px;
top: 2100px;

border: 5px solid #CC00FF;
border-radius: 10px;
}

.expStyleBox{
   
  position: absolute;
  left: 430px;
  top: 2080px;
  width: 120px;
  height: 50px;
  background:#080c1e ; 
}

.expStyleBox2{
   
  position: absolute;
  left: 950px;
  top: 2080px;
  width: 255px;
  height: 50px;
  background:#080c1e ; 
}

.emStyleBox{
  position: absolute;
  left: 5.8%;
  top: 95.3%;
  width: 140px;
  height: 50px;
  background:#080c1e ;
}

.emNameBox{
  position: absolute;
  font-size: 55px;
  color: #FFFFFF;
  left: 6%;
  top:95%;
  width: 260px;
  height: 50px;
}

.emConBox{
position:absolute;
width: 800px;
height: 100px;
left: 4%;
top: 95.6%;

border: 5px solid #CC00FF;
border-radius: 10px;

}

.emLinkBox{
  position: absolute;
  width: 300px;
  height: 200px;
  top:96%;
  left:16%;
  font-size: 40px;
  color: #FFFFFF;

}

.emStyleBox2{
  position: absolute;
  left: 5.8%;
  top: 98.3%;
  width: 182px;
  height: 50px;
  background:#080c1e ;
}

.emNameBox2{
  position: absolute;
  font-size: 46px;
  color: #FFFFFF;
  left: 6%;
  top:98%;
  width: 260px;
  height: 50px;
}

.emConBox2{
position:absolute;
width: 800px;
height: 100px;
left: 4%;
top: 98.6%;

border: 5px solid #CC00FF;
border-radius: 10px;

}

.emLinkBox2{
  position: absolute;
  width: 600px;
  height: 200px;
  top:98.9%;
  left:13.5%;
  font-size: 37px;
  color: #FFFFFF;
}
.expStyleBox2{
   
  position: absolute;
  left: 950px;
  top: 2080px;
  width: 255px;
  height: 50px;
  background:#080c1e ; 
}

.expStyleBox4{
   
  position: absolute;
  left: 950px;
  top: 2080px;
  width: 260px;
  height: 50px;
  background:#080c1e ; 
}

.expStyleBox3{
   
  position: absolute;
  left: 430px;
  top: 2080px;
  width: 200px;
  height: 50px;
  background:#080c1e ; 
}

.expStyleBox6{
   
  position: absolute;
  left: 430px;
  top: 2080px;
  width: 120px;
  height: 50px;
  background:#080c1e ; 
}

.expStyleBox5{
  position: absolute;
  left: 987px;
  top: 2080px;
  width: 170px;
  height: 50px;
  background:#080c1e ;
}

.expCDate{
  position: absolute;
  font-size: 25px;
  color: #FFFFFF;
  left: 960px;
  top: 2080px;
  width: 260px;
  height: 50px;
}

.expCDate2{
  position: absolute;
  font-size: 25px;
  color: #FFFFFF;
  left: 1000px;
  top: 2080px;
  width: 260px;
  height: 50px;
}

.expCName{
  position: absolute;
  font-size: 55px;
  color: #FFFFFF;
  left: 437px;
  top: 2060px;
  width: 200px;
  height: 50px;
}



.gradText{
  position:absolute;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 30px;
  font-weight: bold;
  left:1170px ;
  top: 2720px;
}

.contactHead{
  position: relative;
  top: 5800px;
  width:800px;
  left: 3.2%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 100px;
  color:rgb(255, 0, 255);

}

.contactText{
  position: relative;
  width:800px;
  top: 5900px;
  left: 6%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 50px;
  color:white;
}

html{
  scroll-behavior: smooth;

}

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;

}

a:hover {
  text-decoration: none;
  color: white;

}

a:active {
  text-decoration: none;
  color: white;

}

.navbar{
 position: fixed;
 display:flex;
 flex-direction: row;
 top: 0px;
 left: -135px;
 width:2200px;
 height: 65px;
 gap:150px;
 background: #000000;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navContainer{
  position: fixed;
  display:flex;
  flex-direction: row;
  gap:10px;
  top:0px;
  width: 1920px;
  height:85px;

}

.navSection{
position: relative;
top:20%;
left: 10%;
color: white;
font-size: 30px;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}




.skillBox{
width: 140px;
height: 51px;


border: 5px solid #DB00FF;
box-sizing: border-box;
border-radius: 50px;

}

.skillContainer{
  position: relative;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 28px;
  text-align: center;
  gap: 10px ;
  left: -785px;
  top: 55px;
}

.gitLink{
  position: relative;
  top:65%;
  color: white;
  font-size: 28px;
  font-weight: bold;
  text-decoration-line: underline;
  transition-duration: 0.4s;
  text-decoration-thickness:10px;
}

.gitLink:hover{
  text-emphasis-color: purple;
  font-size: 25px;


}

.container{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 90px ;
  left: 860px;
  top: 3350px;
}
.rect{
width: 600px;
height: 320px;


box-sizing: border-box;
border-radius: 5px;
}


.eduText{
  
position: absolute;
width: 740px;
height: 71px;
left:700px ;
top: 2770px;

font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 97px;
text-align: center;

color: #FFFFFF;
}

.expText{
position: absolute;
width: 900px;
height: 71px;
left:400px ;
top: 2150px;

font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 97px;
text-align: center;

color: #FFFFFF;
}



.about{
position: absolute;
width: 1153px;
height: 481px;
left: 100px;
top: 1050px;

justify-content: right;

font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 35px;
line-height: 97px;
text-align: center;

color: #ffffff;
}



.projHead{
  position: relative;
  color:#FFFFFF;
  left: -130%;
  font-size: 35px;
}
.projText{
  position: relative;
  color:#FFFFFF;
  left: -130%;
  font-size: 30px;
}
.exp{
  position: absolute;
  width: 1144px;
  height: 246px;
  top: 2150px;
  left:-320px;
  color:rgb(255, 0, 255);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 87.6%;
  /* or 88px */ 
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.edu{
  position: absolute;
  width: 1144px;
  height: 246px;
  top: 2770px;
  left:-320px;
  color:rgb(255, 0, 255);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 87.6%;
  /* or 88px */ 
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.proj{
  position: absolute;
  width: 1144px;
  height: 246px;
  top: 3200px;
  left:-320px;
  color:rgb(255, 0, 255);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 87.6%;
  /* or 88px */ 
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.contact{
  position: absolute;
  width: 1144px;
  height: 246px;
  top: 4200px;
  left:-320px;
  color:rgb(255, 0, 255);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 87.6%;
  /* or 88px */ 
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.skill{
position: absolute;
width: 173px;
height: 51px;


border: 5px solid #DB00FF;
box-sizing: border-box;
border-radius: 50px;
}

.svg{
position: absolute;
width: 50%;
height: 50%;
left: 50%;
top: 110px;
background: #080c1e;
}

.svg2{
  position: absolute;
  top:2150px;
  left:200px;
  background: #080c1e;
}

.name{
  position: relative;
  width: 1144px;
  height: 246px;
  left: -295px;
  top: 200px;
  color:#ff00ff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 75px;
  line-height: 87.6%;
  /* or 88px */ 
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

  .role{
  position: relative;
  width: 1100px;
  height: 246px;
  left: -230px;
  top: 100px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 87.6%;
  /* or 88px */ 
  text-align: center;
  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rbox{
  position: relative;
  left: 60px;
  top: 100px;
  font-size: 30px;
  width: 1100px;
  text-align: center;
  color: white

}


.rtext {
  width: 17ch;
  animation: typing 2s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.res{
  position: absolute;
  width: 338px;
  height: 73px;
  left: 45px;
  top: 500px;
  border: 5px solid #ff00ff;
  box-sizing: border-box;
  border-radius: 50px;
  transition-duration: 0.4s;

  
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 48px;
line-height: 87.6%;
color: #FFFFFF;
text-align: center;

}

.res:hover{
  background-color: #ff00ff;

}



.resText{
  position: relative;
  top: 10%;
  
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

 
